import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOrder {
    lookupService = new APIServiceLookUp();

    async getAllOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getAllOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateOrder(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/updateOrder`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}