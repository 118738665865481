import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

import ServiceOverview from '../../../../services/serviceOverview';

export default function ComponentNewOrderList() {
    const [token] = useRecoilState(tokenAtom);

    const [orders, setOrders] = useState([]);

    const [loading, setLoading] = useState(true);

    const overviewService = new ServiceOverview();

    const loadData = () => {
        setLoading(true);
        (async function () {
            const res = await overviewService.getNewOrders({ 'status': 'active' }, token);
            setOrders(res.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-12 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <h3 className='text-lg font-sans font-medium'>New Enquiries | Top 10</h3>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                # OID
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Customer Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Company Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Customer Email
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Customer Phone
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Items
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Created At
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            orders.length !== 0 && orders.map((order, index) => {
                                return <tr className="hover:bg-gray-100" key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div className='flex items-center text-prime uppercase'>
                                                {order.ordID}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                            {order.name}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                            {order.cname}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                            {order.email}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                            {order.phone}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                            Items: ({order.items.length})
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-blue-600">
                                            {moment(order.createdAt).format('DD MMM, yyyy')}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${order.status === 'closed' ? 'bg-green-100 text-green-800' : order.status === 'active' ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800'}`}>
                                            {order.status.toUpperCase()}
                                        </span>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="8">
                                    <div className="text-sm text-blue-600 text-center">
                                        No data found.
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
