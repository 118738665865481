import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import Moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import moment from 'moment';
import { motion } from "framer-motion";

import { Calendar as CalendarIcon } from "lucide-react";

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceOrder from 'services/serviceOrder';

export default function PanelOrderList() {

    const [token] = useRecoilState(tokenAtom);

    const [orders, setOrders] = useState([]);

    const [order, setOrder] = useState({});
    const [filter, setFilter] = useState({});

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [selection, setSelection] = useState(false);

    const orderService = new ServiceOrder();

    const openOrder = (ord) => {
        setOrder(ord);
        setSelection(true);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setDate({
            from: Moment().startOf('year').toDate(),
            to: Moment().toDate(),
        });
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "closed":
                setFilter({ 'status': 'closed' });
                break;
            case "cancel":
                setFilter({ 'status': 'cancel' });
                break;
            default:
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        orders.forEach((item) => {
            item.items.forEach((rawItem) => {
                items.push({
                    name: item.name,
                    cname: item.cname,
                    email: item.email,
                    phone: item.phone,
                    orderId: item.ordID,
                    product: rawItem.title,
                    brand: rawItem.brand,
                    qty: rawItem.size,
                    status: item.status,
                    createdAt: item.createdAt,
                });
            })
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "order");
        XLSX.writeFile(workbook, "Enquiries.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['name,cname,email,phone,orderId,product,brand,qty,status,created At'];
        let usersCsv = orders.reduce((acc, user) => {
            const { name, cname, email, phone, ordID, items, status, createdAt } = user
            items.forEach((rawitem) => {
                acc.push([name, cname, email, phone, ordID, rawitem.title, rawitem.brand, rawitem.size, status, createdAt].join(','))
            });
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'Enquiries.csv',
            fileType: 'text/csv',
        });
    }

    const updateStatus = (id, status) => {
        let body = {
            uid: id,
            data: {
                status: status
            }
        }
        orderService.updateOrder(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    const getChipColorA = (status) => {
        switch (status) {
            case "active":
                return "bg-blue-100 text-blue-800";
            case "closed":
                return "bg-green-100 text-green-800";
            case "cancel":
                return "bg-red-100 text-red-800";
            default:
                return "bg-gray-100 text-gray-800";
        }
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await orderService.getAllOrders(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setOrders(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await orderService.getAllOrders(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setOrders(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, date, filter]);

    return (
        <>
            {
                selection && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 min-h-screen p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>ENQUIRY #{order.ordID}</h1>
                                    <span onClick={() => {
                                        setSelection(false);
                                        setOrder({});
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>
                                        Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                            {order.status}
                                        </span>
                                    </p>
                                    <p className='font-sans font-medium flex items-center ml-4'>
                                        <i class="las la-calendar text-xl font-medium mr-1"></i>
                                        Enquiry Date:&nbsp;<span className="text-gray-800 font-normal text-sm">{Moment(order.createdAt).format('DD MMM, yyyy')}</span>
                                    </p>
                                </div>
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span>
                                        <i className="las la-file-invoice-dollar mr-2 text-lg"></i>Enquiry Summary
                                    </span>
                                </div>
                                <div className='flex flex-col mt-4'>
                                    {
                                        order.items.map((productItem) => {
                                            return <div className="w-full flex items-center my-2 mx-auto" key={productItem._id}>
                                                <div className="w-[60px] p-2 bg-gray-200 rounded-md">
                                                    <img src={productItem.fileurl} height={180} width={180} alt="Product Icons" className="w-full h-full" />
                                                </div>
                                                <div className='flex-gorw flex flex-col justify-start ml-4'>
                                                    <h2 className="text-base hover:underline cursor-pointer">{productItem.title}</h2>
                                                    <p className='text-sm'>Brand : {productItem.brand}</p>
                                                    <p className='text-sm'>Package Size : {productItem.size}</p>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4'><i className="las la-user mr-2 text-lg"></i>Customer</div>
                                <div className='mt-4 space-y-1'>
                                    <p className='text-sm font-medium'>{order.name}</p>
                                    <p className='text-sm text-gray-600'>Company Name: <b>{order.cname}</b></p>
                                    <p className='text-sm text-gray-600'>Phone no.: (+91) {order.phone}</p>
                                    <p className='text-sm text-gray-600'>Email: {order.email}</p>
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <ScrollArea className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-prime">
                                Enquiries Master
                            </h2>
                            <TopBar />
                        </div>
                        <div class="ml-2 w-full mt-4 flex justify-between items-center">
                            <div className='h-12 bg-gray-100 rounded'>
                                <ul className="intro-y flex text-sm font-sans">
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'all' ? 'text-ascent font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'active' ? 'text-ascent font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('active') }}>Active</button></li>
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'closed' ? 'text-ascent font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('closed') }}>Closed</button></li>
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'cancel' ? 'text-ascent font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('cancel') }}>Cancelled</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={9}>
                                        <div className='w-full grid grid-cols-12 gap-2'>
                                            <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                <div className='col-span-3 flex'>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button id="date" variant={"outline"}
                                                                className={cn(
                                                                    "w-[300px] justify-start text-left font-normal",
                                                                    !date && "text-muted-foreground"
                                                                )}
                                                            >
                                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                                {date?.from ? (
                                                                    date.to ? (
                                                                        <>
                                                                            {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                            {Moment(date.to).format('MMM DD, yyyy')}
                                                                        </>
                                                                    ) : (
                                                                        Moment(date.from).format('MMM DD, yyyy')
                                                                    )
                                                                ) : (
                                                                    <span>Pick a date</span>
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                initialFocus
                                                                mode="range"
                                                                defaultMonth={date?.from}
                                                                selected={date}
                                                                onSelect={onDateChange}
                                                                numberOfMonths={2}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                                <div className='w-full flex col-span-9 items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name, email, phone and company name." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='col-span-1 flex items-center'>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToExcel();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToCsv();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        # RAQ
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Customer Name
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Company Name
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Customer Email
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Customer Phone
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Items
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    orders.length !== 0 && orders.map((order, index) => {
                                        return <tr className="hover:bg-gray-100" key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => { openOrder(order) }}>
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-prime uppercase'>
                                                        {order.ordID}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                                    {order.name}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                                    {order.cname}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                                    {order.email}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                                    {order.phone}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-sm leading-5 font-semibold text-gray-900">
                                                    Items: ({order.items.length})
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {moment(order.createdAt).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${order.status === 'closed' ? 'bg-green-100 text-green-800' : order.status === 'active' ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800'}`}>
                                                    {order.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-60px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    {
                                                                        order.status == 'active' && <DropdownMenuItem>
                                                                            <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                                updateStatus(order._id, 'closed');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-chevron-circle-up text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Make Closed</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                    }
                                                                    {
                                                                        order.status == 'active' && <DropdownMenuItem>
                                                                            <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                                updateStatus(order._id, 'cancel');
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <i className="las la-ban text-lg"></i>
                                                                                    <p className='text-sm font-serif ml-2'>Make Cancelled</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="9">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </>
    )
}
